import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { confirmEmail } from '../../shared/validations/checkFormDatas';
import { authActions } from '../../redux/reducer/auth/auth';
import AuthService from '../../redux/service/authService/AuthService';
import PATHS from '../../router/paths';
import showToast from '../../shared/components/notification/Notification';

export const AuthContext = createContext();
export const useAuthContext = () => { return useContext(AuthContext); }

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const authService = new AuthService(authActions)

    const { error } = useSelector((state) => state.auth)

    const [showPassword, setShowPassword] = useState(false)
    const [formState, setFormState] = useState({
        email: "",
        password: "",
        emailMessage: "",
        passwordMessage: "",
        isFormInvalid: true,
    })

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleChangeFormDatas = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...formState,
            [name]: value
        })
    }

    const handleLogin = async () => {
        if(!formState.isFormInvalid){
            const { email, password } = formState
            const response = await dispatch(authService.authenticationUser({ email, password }))
            if(response){
                return navigate(`/${PATHS.DASHBOARD}/${PATHS.HOME}`)
            }
            return false
        }
    }

    useEffect(() => {
        const passwordError = (formState.password.length < 6 || formState.password.length > 12) ? "A senha deve ter entre 6 e 12 caracteres" : ""
        const emailError = !confirmEmail(formState.email) ? "Email inválido" : ""
        setFormState((prevState) => ({
            ...prevState,
            emailMessage: emailError,
            passwordMessage: passwordError,
            isFormInvalid: emailError !== "" || passwordError !== ""
        }))
    }, [formState.email, formState.password])

    useEffect(() => {
        if(error){
            showToast(error.message, error.statusCode)
        }
    }, [error])

    useEffect(() => {
        return () => {
            dispatch(authActions.cleanStore())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const state = {
        formState,
        setFormState,
        handleChangeFormDatas,
        showPassword,
        setShowPassword,
        toggleShowPassword,
        handleLogin,
    }

    return (
        <AuthContext.Provider value={state}>
            {children}
        </AuthContext.Provider>
    )
}