import { createSlice } from "@reduxjs/toolkit";

var initialState = {
    loading: false,
    list: {
        data: [],
        page: 0,
        totalItems: 0,
        rowsPerPage: 10,
        searchTerm: null,
        totalPages: 0,
    },
    item: {},
    tableItem: {},
    error: null,
};

class reducerFactory {
    constructor(slice, state) {
        const reducerResult = createSlice({
            name: slice,
            initialState,
            reducers: this._generateReducers(),
        });
        this.slice = slice;
        this.reducer = reducerResult.reducer;
        this.actions = reducerResult.actions;
    }

    _generateReducers = () => {
        return {
            requestData: (state) => {
                state.loading = true;
                state.error = null;
            },
            requestDataPaginationSucess: (state, action) => {
                state.loading = false;
                state.list.data = action.payload.data ? action.payload.data : [];
                state.list.page = action.payload.currentPage ? action.payload.currentPage : 0;
                state.list.totalItems = action.payload.totalItems ? action.payload.totalItems : 0;
                state.list.totalPages = action.payload.totalPages ? action.payload.totalPages : 0;
                state.list.rowsPerPage = action.payload.rowsPerPage ? action.payload.rowsPerPage : 10;
            },
            requestDataSuccess: (state, action) => {
                state.loading = false;
                state.list.data = action.payload;
            },
            requestDataError: (state, action) => {
                state.loading = false;
                state.list = {
                    data: [],
                    page: 0,
                    totalItems: 0,
                    rowsPerPage: 10,
                    searchTerm: null,
                    totalPages: 0,
                };
                state.error = action.payload;
            },
            requestGetItem: (state, action) => {
                state.loading = true;
                state.error = null;
            },
            requestGetItemSuccess: (state, action) => {
                state.loading = false;
                state.item = action.payload;
            },
            requestGetItemError: (state, action) => {
                state.loading = false;
                state.item = {};
                state.error = action.payload;
            },
            requestDeleteItem: (state, action) => {
                state.loading = true;
                state.error = null;
            },
            requestDeleteItemSuccess: (state, action) => {
                state.loading = false;
            },
            requestDeleteItemError: (state, action) => {
                state.loading = false;
                state.error = action.payload;
            },
            requestCreateItem: (state, action) => {
                state.loading = true;
                state.error = null;
            },
            requestCreateItemSuccess: (state, action) => {
                state.loading = false;
            },
            requestCreateItemError: (state, action) => {
                state.loading = false;
                state.error = action.payload;
            },
            requestUpdateItem: (state, action) => {
                state.loading = true;
                state.error = null;
            },
            requestUpdateItemSuccess: (state, action) => {
                state.loading = false;
            },
            requestUpdateItemError: (state, action) => {
                state.loading = false;
                state.error = action.payload;
            },
            setItemToEdit: (state, action) => {
                state.tableItem = action.payload;
            },
            cleanStore: (state, action) => {
                return { ...initialState };
            },
        };
    };
}

export default reducerFactory;