const PATHS ={
    INIT: "/",
    HOME: "home",
    DASHBOARD: "dashboard",
    LOGIN: "login",
    REGISTER: "cadastrar",
    USERS: "usuarios",
    NOT_FOUND: "404",
    RECOVER_PASSWORD: "esqueci-senha",
    CONTACT: "contato",
    FINES: "multas",
    CUSTOMERS: "clientes",
    FINES_REPORTS: "relatorios/multas-recursos",
    ADMINS: "administradores",
    PROFILE: "perfil",
    ADMINS_FORM_EDIT: "administradores/edicao",
    ADMINS_FORM_NEW: "administradores/novo",
    FINE_TYPE: "tipos-multas",
    FINE_TYPE_NEW: "tipos-multas/novo",
    FINE_TYPE_EDIT: "tipos-multas/edicao",
    USER_FINES_LIST: "usuarios/multas",
    FINES_FORM_NEW: "multas/novo",
    PAYMENT_PAGE: "pagamento"
}

export default PATHS;