import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'

export default function TextSection({ title, width, children }) {
    const theme = useTheme()
    return (
        <Box display="flex" flexDirection="column" maxWidth={width ? width : "210px"} sx={{ backgroundColor: theme.palette.primary }}>
            <Typography
                variant='button'
                fontSize={18}
                color="white"
            >
                {title}
            </Typography>
            <div className='rounded-detail'/>
            <Typography variant='caption' color="white">{children}</Typography>
        </Box>
    )
}
