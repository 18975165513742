import { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingScreen from '../components/loadingScreen/LoadingScreen';
import PATHS from './paths';

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();
    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes(PATHS.DASHBOARD)} />}>
            <Component {...props} />
        </Suspense>
    );
};

export default Loadable;