import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import { AuthProvider } from './context/auth/AuthContext';
import { ThemeProvider } from './context/theme/ThemeContext';
import './scss/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/pt-br';
import moment from 'moment';

moment.locale('pt-br');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='pt-br'>
        <ThemeProvider>
          <AuthProvider>
            <App />
            <ToastContainer limit={1} />
          </AuthProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  </BrowserRouter >
);