import { createTheme } from '@mui/material/styles';
import { breakpoints } from './breakpoints';

const customBreakpoints = createTheme({
    breakpoints: breakpoints,
})

export const lightTheme = createTheme({
    ...customBreakpoints,
    palette: {
        mode: 'light',
        primary: {
            main: '#9e763f',
            light: "#ffffff",
            contrastText: "#000000"
        },
        secondary: {
            main: '#abb2ae',
            light: '#abb2ae',
            contrastText: '#000000',
        },
        background: {
            default: '#ededed',
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {

            },
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    maxHeight: "100%"
                }
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    maxHeight: '600px',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                        height: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#cfcfcf',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#888',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#555',
                    },
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    maxHeight: "100%",
                    position: "sticky",
                    top: 0,
                    zIndex: 1000,
                    backgroundColor: "#ffffff",
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ffffff",
                    fontSize: "14px",
                    fontWeight: "bold",
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    position: "sticky",
                    bottom: 0,
                    zIndex: 1000,
                    backgroundColor: "#ffffff"
                }
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    lineHeight: '19px',
                },
                head: {
                    fontWeight: 'bold',
                },
            },
        },
    },
});

export const darkTheme = createTheme({
    ...customBreakpoints,
    palette: {
        mode: 'dark',
        primary: {
            main: '#aa895a',
            dark: "#3B3B3B",
            contrastText: "#ffffff"
        },
        background: {
            default: "#262626",
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {

            },
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    maxHeight: "100%"
                }
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    maxHeight: '600px',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                        height: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#cfcfcf',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#888',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#555',
                    },
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    maxHeight: "100%",
                    position: "sticky",
                    top: 0,
                    zIndex: 1000,
                    backgroundColor: "#ffffff",
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ffffff",
                    fontSize: "14px",
                    fontWeight: "bold",
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    position: "sticky",
                    bottom: 0,
                    zIndex: 1000,
                    backgroundColor: "#ffffff"
                }
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                },
                head: {
                    fontWeight: 'bold',
                },
            },
        },
    },
});