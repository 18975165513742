import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function showToast(message, statusCode, onCloseFunction) {
    let toastType;
    if(statusCode >= 200 && statusCode < 300){
        toastType = "success";
    }
    if(statusCode >= 400 && statusCode < 500){
        toastType = "error";
    }
    if(statusCode >= 500){
        toastType = "error";
    }
    if(statusCode === 401){
        toastType = "warning";
    }

    return toast(message, {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        type: toastType,
        onanimationend: onCloseFunction ? () => onCloseFunction() : () => {}
    });
}
