import React, { createContext, useContext, useEffect, useState } from 'react';
import { confirmEmail } from '../../shared/validations/checkFormDatas';

const FooterContext = createContext();

export const useFooterContext = () => {
    return useContext(FooterContext);
};

export const FooterProvider = ({ children }) => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const submitNewsletter = (e) => {
        alert(email)
    }

    useEffect(() => {
        if(email.length > 2){
            const emailError = !confirmEmail(email) ? "Email inválido" : ""
            setEmailError(emailError)
        }
        if(email.length === 0){
            setEmailError('')
        }
    }, [email])

    return(
        <FooterContext.Provider value={{email, setEmail, submitNewsletter, emailError, setEmailError}}>
            {children}
        </FooterContext.Provider>
    )
}