import { createContext, useContext, useState } from 'react';
import { getThemeHelper, setThemeHelper } from '../../shared/themeHelper/themeHelper';

export const ThemeContext = createContext();
export const useThemeContext = () => { return useContext(ThemeContext); }

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(getThemeHelper() ? getThemeHelper() : 'light');

    const toggleTheme = (theme) => {
        setTheme(theme);
        setThemeHelper(theme)
    }

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}