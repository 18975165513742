import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import getMenuOptionsByUserRole from "../../../configs/menuByRole";
import { useThemeContext } from "../../../context/theme/ThemeContext";
import PATHS from "../../../router/paths";
import Logo from "../../../shared/components/Logo";

export default function CustomHeader() {
  const navigate = useNavigate();
  const { theme } = useThemeContext();

  const handleListItemClick = (path) => {
    navigate(path);
  };

  const menuOptions = getMenuOptionsByUserRole();

  return (
    <Box
      className="Header"
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems="center"
      padding={1}
      sx={{
        width: "100%",
        maxWidth: "100vh",
        boxSizing: "border-box",
        
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-start" },
          width: { xs: "100%", sm: "auto" },
        }}
      >
        <Button
          sx={{
            padding: 0,
            lineHeight: 0,
            fontSize: 0,
            width: { xs: "120px", sm: "180px" },
            height: { xs: "35px", sm: "50px" },
          }}
          onClick={() => navigate(`${PATHS.INIT}`)}
        >
          <Logo theme={theme} width="100%" height="100%" />
        </Button>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        gap={{ xs: 2, sm: 6 }}
        flexDirection={{ xs: "column", sm: "row" }}
        sx={{
          width: { xs: "100%", sm: "auto" },
          justifyContent: { xs: "center", sm: "flex-end" },
        }}
      >
        {menuOptions &&
          Array.isArray(menuOptions) &&
          menuOptions.map((option, index) => (
            <Typography
              key={index}
              onClick={() => handleListItemClick(option.path)}
              fontWeight="bold"
              fontSize={14}
              sx={{
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              {option.label}
            </Typography>
          ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-end" },
          width: { xs: "100%", sm: "auto" },
        }}
      >
        <Button
          variant="contained"
          onClick={() => navigate(`${PATHS.LOGIN}`)}
          sx={{
            fontSize: { xs: 12, sm: 14 },
            padding: { xs: "5px 10px", sm: "10px 20px" },
          }}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
}
