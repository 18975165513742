import axios from "axios";
import { getToken } from "./authHelper";

export const API_URL = 'https://api.scmultas.com.br/api';
export const concatUrl = (url) => `${API_URL}${url}`;

export const getRequestHeaders = () => {
    return {
        Authorization: `${getToken() ? getToken() : ''}`
    }
}

export const requestService = {
    get: async (url) => axios.get(`${API_URL}${url}`, { headers: getRequestHeaders() }),
    post: async (url, data) => axios.post(`${API_URL}${url}`, data, { headers: getRequestHeaders() }),
    put: async (url, data) => axios.put(`${API_URL}${url}`, data, { headers: getRequestHeaders() }),
    delete: async (url) => axios.delete(`${API_URL}${url}`, { headers: getRequestHeaders() }),
    patch: async (url, data) => axios.patch(`${API_URL}${url}`, data, { headers: getRequestHeaders() })
};