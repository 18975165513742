import auth from "./auth/auth";
import register from "./register/register";
import forgotPassword from "./recoverPassword/recoverPassword";
import adminUsers from "./adminUsers/adminUsers";
import fineType from "./fineType/fineTypeReducer";
import fineResources from './finesWithoutResources/finesWithoutResourcesReducer'
import commonUserFines from './commonUserFines/commonUserFinesReducer'
import dashboardUserFines from './adminHomeDashbpard/adminDashboardReducer'
import profile from './profile/profileReducer'
import userFinesList from "./userFinesList/userFinesListReducer";
import adminFinesReport from '../reducer/adminFinesAndResoureceReport/adminFinesAndResoureceReducer'
import fine from '../reducer/fine/fineReducer'
import payment from '../reducer/payment/paymentReducer'

const rootReducer = {
    auth,
    register,
    forgotPassword,
    adminUsers,
    fineType,
    fineResources,
    commonUserFines,
    dashboardUserFines,
    profile,
    userFinesList,
    adminFinesReport,
    fine,
    payment,
}

export default rootReducer;