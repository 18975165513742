import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './menu/LoggedHeader'
import SideBar from './menu/SideBar'

export default function PrivateLayout() {
    return (
        <>
            <Header />
            <div className="main_DashboardLogged">
                <SideBar />
                <Outlet />
            </div>
        </>
    )
}