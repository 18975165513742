import { getUserRoleId } from "../../../shared/helper/authHelper";
import USER_ROLES from "../../../shared/helper/userTypes";
import PATHS from "../../../router/paths";

export default function menuItemsByRole(){
    switch(parseInt(getUserRoleId())){
        case USER_ROLES.ADMIN:
            return[
                {path: `/${PATHS.DASHBOARD}/${PATHS.FINES}`, title: 'Multas'},
                {path: `/${PATHS.DASHBOARD}/${PATHS.FINES_REPORTS}`, title: 'Relatórios'},
                {path: `/${PATHS.DASHBOARD}/${PATHS.ADMINS}`, title: 'Usuários administradores'},
                {path: `/${PATHS.DASHBOARD}/${PATHS.FINE_TYPE}`, title: 'Tipos de multas'},
            ]
        case USER_ROLES.USER:
            return[
                {path: `/${PATHS.DASHBOARD}/${PATHS.HOME}`, title: 'Início'},
                {path: `/${PATHS.DASHBOARD}/${PATHS.USER_FINES_LIST}`, title: 'Multas'},
            ]
        default:
            return []
    }
}