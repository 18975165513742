import React from 'react'
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button';
import { logout } from '../../../shared/helper/authHelper';
import PATHS from '../../../router/paths';
import { Typography } from '@mui/material';

export default function LoggedMenu() {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button variant="contained" onClick={handleClick} sx={{ borderRadius: 2 }}>
                <Typography color="white" fontWeight="bold">perfil</Typography>
            </Button>
            <Menu
                id="logged-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem  onClick={() => navigate(`${PATHS.PROFILE}`)} disableRipple>Perfil</MenuItem>
                <MenuItem onClick={() => { logout(); navigate(`${PATHS.INIT}`) }}>Sair</MenuItem>
            </Menu>
        </div>
    )
}
