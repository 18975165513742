import React from 'react'
import LightLogo from '../../assets/logo-light.webp'
import DarkLogo from '../../assets/logo-dark.png'

export default function Logo({ theme, width, height, center }) {
    return (
        <div>
            <img
                src={theme === 'light' ? LightLogo : DarkLogo}
                alt='logo da SC ACESSORIA E CONSULTORIA'
                width={width}
                height={height}
                loading='lazy'
                style={center ? { margin: '0 auto', display: 'block' } : null}
            />
        </div>
    )
}