import React from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

export default function CustomCard({ gap, cardWidth, boxWidth, children }) {
    return (
        <Card className='footer-card-actions' sx={{ width: cardWidth ? cardWidth : "300px" }}>
            <Box className='custom-box-card-actions' gap={gap ? gap : 5} width={boxWidth ? boxWidth : "250px"}>
              {children}
            </Box>
        </Card>
    )
}