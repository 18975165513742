import { ThemeProvider } from "@mui/material/styles";
import { darkTheme, lightTheme } from "./configs/palette";
import Router from "./router/router";
import { useThemeContext } from "./context/theme/ThemeContext";
import { FooterProvider } from "./context/footer/Footer";
import axios from "axios";
import showToast from "./shared/components/notification/Notification";
import { logout } from "./shared/helper/authHelper";
import PATHS from "./router/paths";
import { useNavigate } from "react-router-dom";

export default function App() {
  const { theme } = useThemeContext();
  const navigate = useNavigate();

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error && error.response && error.response.status === 403) {
        showToast("Sessão expirada, faça o login novamente", 401);
        setTimeout(() => {
          logout();
          navigate(PATHS.LOGIN, { replace: true });
          window.location.reload();
        }, 1500);
      }
      return Promise.reject(error);
    }
  );

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <FooterProvider>
        <Router />
      </FooterProvider>
    </ThemeProvider>
  );
}