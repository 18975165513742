import React from "react";
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom';
import LoggedMenu from "./Menu";
import CustomAvatar from "../../../shared/components/Avatar";
import { headersLinks } from "./headerItems";
import { getUser } from "../../../shared/helper/authHelper";

function Header() {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="row" height={80} paddingX={4}>
      <Box display="flex" gap={4}>
        {headersLinks.map((item, index) => {
          return (
            <Link key={index} to={item.path} style={{ textDecoration: 'none', color: "black" }}>
              {item.title}
            </Link>
          )
        })}
      </Box>

      <Box display="flex" alignItems="center" gap={3}>
        <CustomAvatar userName={getUser() ? getUser().name : null} />
        <LoggedMenu />
      </Box>
    </Box>
  );
}

export default Header