import errorModel from "../../../shared/errorModel";
import { setToken, setUser, setUserRoleId } from "../../../shared/helper/authHelper";
import { requestService } from "../../../shared/helper/requestService";
import BaseService from "../BaseService";

export default class AuthService extends BaseService{

    authenticationUser = (payload) => async dispatch => {
        const {email, password} = payload;
        dispatch(this.actions.requestData())
        try{
            const { data } = await requestService.post('/user/login', {email, password});
            if(data && data.token && data.user){
                const user = data.user
                dispatch(this.actions.requestDataSuccess(user))
                setUser(data.user)
                setToken(data.token)
                setUserRoleId(data.user.userRoleId)
                return true
            }
        }catch(e){
            dispatch(this.actions.requestDataError(errorModel(e)))
        }
    }

}