import React from "react";
import logo from "../../../assets/logo-dark.png";
import poiter from "../../../assets/pointer.svg";
import { useNavigate } from "react-router-dom";
import menuItemsByRole from "./menuItems";
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from "../listItem/CustomStyledListItems";
import { getActive } from "../../../shared/isActivePath";

function SideBar() {
  const navigate = useNavigate();
  return (
    <div className="SideBar" style={{ height: '100vh' }}>
      <img src={logo} alt="" />
      <div className="LinksUser">
        {menuItemsByRole().map((item, index) => {
          return (
            <ListItemStyle key={index + 1} activeRoot={getActive(item.path, window.location.pathname)} onClick={() => navigate(item.path)}>
              <ListItemTextStyle key={index + 1 * 2}>{item.title}</ListItemTextStyle>
              <ListItemIconStyle key={index + 1 * 3}> <img src={poiter} alt="pointer"/> </ListItemIconStyle>
            </ListItemStyle>
          )
        })}
      </div>
    </div>
  );
}

export default SideBar