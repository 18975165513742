import React from 'react';
import Avatar from '@mui/material/Avatar';

function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}

function stringAvatar(name) {
    const nameParts = name.split(' ');
    const initials = nameParts.length === 1 ? nameParts[0][0] : `${nameParts[0][0]}${nameParts[1][0]}`;
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: initials,
    };
}

export default function CustomAvatar({ userName, hasImage, image }) {
    return (
        <>
            {hasImage ? (
                <Avatar alt={`imagem de perfil de ${userName ? userName : "não informado"}`} src={image} />
            ) : (
                <Avatar {...stringAvatar(userName ? userName.toUpperCase() : null)} />
            )}
        </>
    );
}