import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Paper } from '@mui/material';
import { MainStyle } from './layoutStyle';
import Footer from './footer/Footer';
import CustomHeader from './header/CustomHeader';

export default function DashboardLayout() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Paper elevation={6} square>
                <CustomHeader />
            </Paper>
            <MainStyle>
                <Outlet />
            </MainStyle>
            <Footer />
        </Box>
    )
}