import { configureStore, combineReducers } from "@reduxjs/toolkit";
import rootReducer from "./reducer";

const reducer = combineReducers(rootReducer);

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
})

export default store;