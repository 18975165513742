const TOKEN = '@coin';
const USER = '@user';
const USER_ROLE = '@role';

export const setToken = (token) => { return sessionStorage.setItem(TOKEN, JSON.stringify(token)); }
export const getToken = () => { return JSON.parse(sessionStorage.getItem(TOKEN)); }

export const setUser = (user) => { return sessionStorage.setItem(USER, JSON.stringify(user)); }
export const getUser = () => { return JSON.parse(sessionStorage.getItem(USER)); }

export const getUserRoleId = () => { return sessionStorage.getItem(USER_ROLE); }
export const setUserRoleId = (roleId) => { return sessionStorage.setItem(USER_ROLE, JSON.stringify(roleId)); }

export const isAuthenticated = () => { const token = getToken(); return token !== null; }

export const logout = () => { 
    sessionStorage.clear();
}