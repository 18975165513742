import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TextSection from './TextSection'
import Logo from '../../../shared/components/Logo'
import CustomCard from '../../footerCard/CustomCard'
import { useThemeContext } from '../../../context/theme/ThemeContext';
// import { useFooterContext } from '../../../context/footer/Footer';
// import CustomTextField from '../../textField/CustomTextField';

export default function Footer() {
    const { theme } = useThemeContext();
    // const { email, setEmail, submitNewsletter,emailError } = useFooterContext();
    return (
        <div>
            {theme === 'dark' ? <hr className='footer-line' /> : null}
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" bgcolor={"#262626"}>
                <Box width="100%" padding={3} bottom={0} display="flex" gap={5} justifyContent="space-around">
                    <TextSection title={'sobre nós'} width={300}>
                        <Typography textAlign="justify" fontSize={14}>
                            Na SC Consultoria e Assessoria, somos especializados em fornecer suporte jurídico e prático para pessoas com deficiência (PCD) e taxistas. Nossa missão é garantir que nossos clientes obtenham os benefícios e proteções legais a que têm direito, incluindo isenções de impostos, Recurso em processos administrativos de trânsito e adaptação veicular personalizada.
                            Conte conosco para orientação profissional e apoio integral em todas as questões relacionadas aos seus direitos e mobilidade.
                        </Typography>
                    </TextSection>

                    <TextSection title={'Serviços e cursos'} width={390}>
                        <Typography textAlign="justify" fontSize={14}>
                            <ul className='list-items'>
                                <li>Recursos de multas</li>
                                <li>Recurso contra Cassação/Suspenção de multas</li>
                                <li>Isenção de impostos para pcd e táxi</li>
                                <li>Adaptação veicular</li>
                                <li><Logo width={300} center /></li>
                                <li>
                                    <b>ENDEREÇO</b> <br />
                                    R. Nicolau Schaeffer, 36 - São Luiz, Brusque - SC, 88351-330
                                </li>
                            </ul>
                        </Typography>
                    </TextSection>

                    <CustomCard gap={6}>
                        <Typography variant='button' fontWeight="bold" fontSize={18}>Fale conosco</Typography>
                        <Typography variant='caption' fontWeight="500" fontSize={14} textAlign="center"> Horário de atendimento: <br /> Segunda à Sexta: 09:00 às 17:00 </Typography>
                        <Button className='whatsapp-btn'> <WhatsAppIcon /> Fale com um representante </Button>
                    </CustomCard>

                    {/* <CustomCard gap={3} cardWidth={350} boxWidth={300}>
                        <Typography variant='button' fontWeight="bold" fontSize={18}>Newsletter</Typography>
                        <Typography fontSize={14} textAlign="center"> Fique por dentro de nossas atualizações </Typography>
                        <CustomTextField
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='digite seu email'
                            label="digite seu email"
                            error={!!emailError}
                            helperText={emailError ? emailError : ''}
                            fullWidth
                        />
                        <Button
                            fullWidth
                            variant='contained'
                            className='btn-register-newsletter'
                            onClick={submitNewsletter}
                        >
                            Cadastre-se
                        </Button>
                    </CustomCard> */}
                </Box>
                <Typography variant='caption' marginY={1} color="white">©2024 por SC CONSULTORIA & ASSESSORIA</Typography>
            </Box>
        </div>
    )
}