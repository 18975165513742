import { alpha, styled } from '@mui/material/styles';
import { ListItemText, ListItemButton, ListItemIcon } from '@mui/material';

export const ListItemStyle = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'activeRoot',
})(({ activeRoot, theme }) => ({
    textTransform: 'capitalize',
    maxHeight: 80,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary,
    borderRadius: theme.shape.borderRadius,
    ...(activeRoot && {
        ...theme.typography.subtitle2,
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    }),
    '&:hover': {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        height: 70
    }
}));

export const ListItemTextStyle = styled(ListItemText, {
    shouldForwardProp: (prop) => prop !== 'isCollapse',
})(({ isCollapse, theme }) => ({
    whiteSpace: 'nowrap',
    transition: theme.transitions.create(['width', 'opacity'], {
        duration: theme.transitions.duration.shorter,
    }),
    ...(isCollapse && {
        width: 0,
        opacity: 0,
    }),
}));

export const ListItemIconStyle = styled(ListItemIcon)(({ theme }) => ({
    minWidth: theme.spacing(6),
}));
