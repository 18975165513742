import React from 'react';
import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Loadable from './loadble';
import getRoutes from './loggedRoutes';
import { Private, Public } from './routerFilter';
import DashboardLayout from '../components/layout/DashboardLayout';
import PATHS from './paths';
import PrivateLayout from '../components/layout/PrivateLayout';

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: <DashboardLayout />,
            children: [
                { path: '', element: <Public><Home /></Public> },
                { path: PATHS.LOGIN, element: <Public><Login /></Public> },
                { path: PATHS.NOT_FOUND, element: <NotFound /> },
                { path: PATHS.REGISTER, element: <Public><Register /></Public> },
                { path: PATHS.RECOVER_PASSWORD, element: <Public><RecoverPassword /></Public> },
                // { path: PATHS.CONTACT, element: <Public><Contact /></Public> },
                { path: '*', element: <Navigate to={`/${PATHS.NOT_FOUND}`} replace /> },
            ],
        },
        {
            path: `/${PATHS.DASHBOARD}`,
            element: <Private><PrivateLayout /></Private>,
            children: getRoutes(),
        },
    ]);
}

const NotFound = Loadable(lazy(() => import('../pages/404/NotFound')));
const Login = Loadable(lazy(() => import('../pages/login/Login')));
const Register = Loadable(lazy(() => import('../pages/register/Register')));
const RecoverPassword = Loadable(lazy(() => import('../pages/recoverPassword/RecoverPassword')));
// const Contact = Loadable(lazy(() => import('../pages/contact/Contact')));
const Home = Loadable(lazy(() => import('../pages/home/Home')));