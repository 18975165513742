import { lazy } from 'react';
import { getUserRoleId } from '../shared/helper/authHelper';
import USER_ROLES from '../shared/helper/userTypes';
import { Private } from './routerFilter';
import Loadable from './loadble';
import PATHS from './paths';
import { Navigate } from 'react-router-dom';

export default function getRoutes() {
    switch (parseInt(getUserRoleId())) {
        case USER_ROLES.ADMIN:
            return [
                { path: `/${PATHS.DASHBOARD}`, element: <Navigate to={`${PATHS.HOME}`} replace /> },
                { path: PATHS.HOME, element: <Private><DashboardHome /></Private>, index: true },
                { path: PATHS.FINES, element: <Private><FinesAlloperations /></Private> },
                { path: PATHS.USERS, element: <Private><UserTable /></Private> },
                { path: PATHS.ADMINS, element: <Private><AdminUsers /></Private> },
                { path: PATHS.ADMINS_FORM_EDIT, element: <Private><AdminUserForm /></Private> },
                { path: PATHS.ADMINS_FORM_NEW, element: <Private><AdminUserForm /></Private> },
                { path: PATHS.FINE_TYPE, element: <Private><FineTypeList /></Private> },
                { path: PATHS.FINE_TYPE_NEW, element: <Private><FineTypeForm /></Private> },
                { path: PATHS.FINE_TYPE_EDIT, element: <Private><FineTypeForm /></Private> },
                { path: PATHS.PROFILE, element: <Private><ProfileForm /></Private> },
                { path: PATHS.FINES_REPORTS, element: <Private><AdminFinesWithResources /></Private> },
            ];
        case USER_ROLES.USER:
            return [
                { path: `/${PATHS.DASHBOARD}`, element: <Navigate to={`${PATHS.HOME}`} replace /> },
                { path: PATHS.HOME, element: <Private><CommonDashboardHome /></Private> },
                { path: PATHS.USERS, element: <Private><UserTable /></Private> },
                { path: PATHS.PROFILE, element: <Private><ProfileForm /></Private> },
                { path: PATHS.USER_FINES_LIST, element: <Private><UserFinesListPage /></Private> },
                { path: PATHS.FINES_FORM_NEW, element: <Private><FinesForm /></Private> },
                { path: PATHS.PAYMENT_PAGE, element: <Private><PaymentPage /></Private> },
            ];
        default:
            return [];
    }
}

const UserTable = Loadable(lazy(() => import('../pages/users/UserTable')));
const AdminUsers = Loadable(lazy(() => import('../pages/dashboard/adminUsers/list/AdminUsers')));
const AdminUserForm = Loadable(lazy(() => import('../pages/dashboard/adminUsers/form/index')));
const FinesAlloperations = Loadable(lazy(() => import('../pages/dashboard/finesWithoutResources/FinexIndex')));
const CommonDashboardHome = Loadable(lazy(() => import('../pages/dashboard/CommonUsers/Home/Home')));
const DashboardHome = Loadable(lazy(() => import('../pages/dashboard/home/index')));
const FineTypeForm = Loadable(lazy(() => import('../pages/dashboard/fineType/form/index')));
const FineTypeList = Loadable(lazy(() => import('../pages/dashboard/fineType/list/index')));
const ProfileForm = Loadable(lazy(() => import('../pages/dashboard/profile/ProfileForm')));
const UserFinesListPage = Loadable(lazy(() => import('../pages/dashboard/CommonUsers/finesList/index')));
const AdminFinesWithResources = Loadable(lazy(() => import('../pages/dashboard/adminFinesWithResource/index')));
const FinesForm = Loadable(lazy(() => import('../pages/dashboard/CommonUsers/finesForm/index')));
const PaymentPage = Loadable(lazy(() => import('../pages/dashboard/payment/index')));