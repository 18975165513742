import React from 'react';
import BackDrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export default function LoadingScreen({ isDashboard, ...other }) {
    return (
        <BackDrop open>
            <CircularProgress />
        </BackDrop>
    );
}